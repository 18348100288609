import React, { useMemo, useCallback, useState, Suspense } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Dialog as MUIDialog, DialogActions, DialogContent } from '@mui/material';
import { useIsMobile } from './Responsive';
import Loading from './Loading';
import _ from 'lodash';

let dialogs = {};

export function registerDialog({ id, op, ...dlg }) {
  if (op) {
    dialogs[id + '.' + op] = dlg;
  } else {
    dialogs[id] = dlg;
  }
};

const NotFound = ({ open, cancel }) => {
  if (!open) return null;
  return <><DialogContent>
    <h3 onClick={cancel}>Nincs ilyen ablak: {open.id}.{open.op}</h3>
  </DialogContent>
    <DialogActions>
      <Button onClick={cancel}>Kilép</Button>
    </DialogActions>
  </>
}

function getContent(props) {
  if (!props || !props.open) return { Component: NotFound };
  const { id, op } = props.open;
  return dialogs[id + '.' + op] || dialogs[id] || { Component: NotFound };
}

function onOk(cb) {
  window.addEventListener("closeDlg", (ev) => {
    if(!ev.detail.canceled) cb(ev.detail.value);
  }, { once: true });
}

export const useDialog = () => {
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  let open = null;
  if (state && state.dialog) {
    open = state.dialog;
  }
  const setDialog = useCallback((dlg, waiting, redirect, callback) => {
    if(callback) onOk(callback);
    if (!location.state) location.state = {};
    location.state.dialog = dlg;
    location.state.waiting = waiting;
    console.log("setDialog state ", location.state);
    if (redirect) {
      console.log("redirecting to:", { location, redirect });
      location.state.redirect = redirect;
    }
    if (!dlg && location.state.redirect) {
      history.replace(location.state.redirect);
    } else {
      history.replace(location);
    }
  }, [history, location])

  const [canceled, setCanceled] = useState(false);
  const close = useCallback((opt = {}) => {
    let { waiting } = state;
    if (opt.next) waiting = waiting ? waiting.concat(opt.next) : (Array.isArray(opt.next) ? opt.next : [opt.next]);
    //    console.log("closing Dlg", opt, waiting);
    window.dispatchEvent(new CustomEvent('closeDlg', { detail: { canceled: opt.canceled, value: opt.value } }));

    if (waiting && waiting.length) {
      const next = waiting.shift();
      if (next.pushTo && !opt.canceled) {
        _.set(next.rec, next.pushTo, opt.value);
        window.dispatchEvent(new CustomEvent('pushed', { detail: { rec: next.rec, pushTo: next.pushTo, value: opt.value } }));
      }
      console.log("next dialog: ", next);
      setDialog(next, waiting, opt.redirect);
    } else {
      setDialog(null, false, opt.redirect);
    }
  }, [setDialog, state]);

  const pushDialog = useCallback((dlg, rec, pushTo) => {
    if (location.state) {
      let { dialog, waiting } = location.state;
      console.log('dlg pushDialog', { dialog, waiting });
      if (dialog) {
        if (!waiting) waiting = [];
        dialog.rec = rec;
        dialog.pushTo = pushTo;
        waiting.push(dialog);
      }
      setDialog(dlg, waiting);
    } else {
      setDialog(dlg);
    }
  }, [setDialog])

  const cancel = useCallback((opt) => {
    setCanceled(true);
    close({ ...opt, canceled: true });
  }, [close, setCanceled]);

  return useMemo(() => ({ open, setDialog, close, cancel, canceled, pushDialog }), [open, setDialog, close, cancel, canceled, pushDialog]);
};



export function Dialog() {
  let dlg = useDialog();
  let isMobile = useIsMobile();
  let { Component, fullScreen, size, noesc } = getContent(dlg);
  if (typeof size === 'function' && dlg.open) {
    size = size(dlg.open);
  }

  return <MUIDialog
    open={!!dlg.open}
    fullScreen={isMobile || !!fullScreen}
    fullWidth
    maxWidth={size || 'sm'}
    onClose={(event, reason) => {
      console.log("onClose", reason);
      if (reason !== 'backdropClick') {
        dlg.close();
      }
    }}
    disableEscapeKeyDown={noesc}
  >
    <Suspense fallback={<Loading />}>
      <Component {...dlg} />
    </Suspense>
  </MUIDialog>
}