import React, { useEffect, useRef } from 'react';
import { Typography, List, ListItem, Button, Grid, LinearProgress } from '@mui/material';
import { format, stampFromMoment, stampToMoment, now } from '../../utils.js';
import { EVENT, eventTxt } from '../../common/index.js';
import { useLocale } from '../locales';

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {
  Message, Edit, AltRoute, Delete, SportsScore, PriceChange, PlayCircleOutline, Schedule, DoNotDisturbAlt, LocalShipping,
  FlightTakeoff, FlightLand, Money, Send, Check, Email, Bookmark, Archive, Publish, GetApp, Cached, LocalParking, Cancel, CheckBoxOutlineBlank, Warning,
  AcUnit, CheckBoxOutlined, Close, SubdirectoryArrowRight, ErrorOutline, Upload, MapsHomeWork
} from '@mui/icons-material';

export default function Event({ ev, first, last }) {
  const { __, __stamp, __enum } = useLocale();
  const { stamp, event, r, suly, mennyiseg } = ev;
  let Icon;
  let title;
  if (event === EVENT.TRUCK_ARRIVED) {

  } else {
    Icon = FlightTakeoff;
  }
  switch (ev.event) {
    case EVENT.TRUCK_ARRIVED: title = __('customer.arrived'); Icon = FlightLand; break;
    case EVENT.TRUCK_LOADED: title = __('customer.loaded'); Icon = FlightTakeoff; break;
    case EVENT.TRUCK_UNLOADED: title = __('customer.unloaded'); Icon = FlightTakeoff; break;
  }
  let s = [];
  if (suly) s.push(`${suly} kg`);
  if (mennyiseg) s.push(`${mennyiseg} ${__enum('me', r.me)}`);

  return <TimelineItem>
    <TimelineOppositeContent
      sx={{ m: 'auto 0', maxWidth: 115, whiteSpace: 'pre', padding: 1 }}
      align="right"
      variant="caption"
      color="text.secondary"
    >
      {r.epulet || r.varos}<br />
      {__stamp(stamp)}
    </TimelineOppositeContent>
    <TimelineSeparator>
      {!first && <TimelineConnector />}
      <TimelineDot variant="outlined">
        {Icon ? <Icon /> : null}
      </TimelineDot>
      {!last && <TimelineConnector />}
    </TimelineSeparator>
    <TimelineContent sx={{ py: '12px', px: 2, width: 400 }}>
      <Typography variant="body2" >
        <b>{title}</b>
      </Typography>
      <Typography variant="caption">{s.join(' | ')}</Typography>
    </TimelineContent>
  </TimelineItem>
}
