import React, { useCallback, Suspense } from 'react';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { ResponsiveLayout } from './Responsive';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useLocation, useHistory } from 'react-router-dom';
import Loading from './Loading';
import { ErrorBoundary } from './ErrorBoundary';
const drawerWidth = 450;

const styles = theme => ({
  drawer: {
    position: 'absolute',
    width: drawerWidth,
    top: 0,
    bottom: 0,
    transition: theme.transitions.create('right', {
      duration: theme.transitions.duration.shortest,
    }),
    display: 'flex',
    zIndex: 1200,
    maxWidth: '100vw',
    height: '100vh',
    maxHeight: '100vh',
    background: theme.palette.background.default
  },
  drawerClosed: {
    right: 0 - drawerWidth,
  },

  drawerOpen: {
    right: 0,
  },
});


export const useSidebar = () => {
  const history = useHistory();
  const location = useLocation();
  let { state } = location;
  let open = null;
  if (state && state.sidebar && state.sidebar) {
    open = state.sidebar.current;
  }
  const setSidebar = useCallback((sb) => {
    if (!sb && state && state.sidebar && state.sidebar.cnt) {
      history.go(-state.sidebar.cnt);
    }
    if (sb) {
      let st = state;
      if (!st) st = {};
      let {sidebar} = st;
      if (!sidebar) sidebar = { cnt: 0 };
      sidebar.current = sb;
      sidebar.cnt++;
      history.push({ ...location, state: {...state, sidebar} });
    }
  }, [history, location, state])
  const clear = useCallback(() => history.replace({ ...location, state: {} }), [history, location, state]);
  const close = useCallback(() => setSidebar(null), [setSidebar]);

  const reopen = useCallback(() => setSidebar(open), [open, setSidebar]);
  const value = React.useMemo(() => ({ open, close, clear, setSidebar, reopen }), [open, close, clear, setSidebar, reopen]);

  return value;
};


let panels = {};
export function registerPanel({ id, Component, width }) {
//  console.log("registerpanel", id);
  panels[id] = { Component, width };
};

const SidebarDesktop = withStyles(styles)(({ classes, open, children }) => <Paper elevation={6} className={classes.drawer + ' ' + (open ? classes.drawerOpen : classes.drawerClosed)}>
  <ErrorBoundary>
    {children}
  </ErrorBoundary>
</Paper>)
const SidebarMobile = ({ children }) => {
  const { open, close, reopen } = useSidebar();

  return <SwipeableDrawer
    variant="temporary"
    anchor='right'
    open={!!open}
    onOpen={reopen}
    onClose={close}
    style={{ width: '100%' }}
    minFlingVelocity={700}
    histeresis={0.7}
    disableSwipeToOpen={true}
  >
    <Box sx={{ width: '100vw', height: '100vh', background: theme => theme.palette.background.default }}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </Box>
  </SwipeableDrawer>
}


export function Sidebar(props) {
  const sidebar = useSidebar();
  const { open } = sidebar;
  let content = null;
  if (open) {
    props = Object.assign({}, props);
    const id = Object.keys(open)[0];
    if (!open[id]) return null;
    const pkey = open[id].id || open[id].sorszam || open[id].kod || open[id];
    console.log("sidebar open", id);
    const { Component, width, fullpage } = panels[id];
    content = <Suspense fallback={<Loading />}>
      <Component id={pkey} tabbed sidebar={sidebar} width={width}/>
    </Suspense>
  }

  return <ResponsiveLayout
    renderDesktop={<SidebarDesktop open={open} {...props}>{content}</SidebarDesktop>}
    renderMobile={<SidebarMobile {...props}>{content}</SidebarMobile>}
  />
}