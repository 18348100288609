
let all = {};
function add(e){
  Object.values(e).map(v=>{
    Object.entries(v).map(([k,v])=>{
      if(all[k]) throw new Error('Duplicate event key '+k);
      if(Object.values(all).includes(v)) throw new Error(`Duplicate event value ${k} = ${v}`);
      all[k] = v;
    })
  })
  return e;
}

export function eventTxt(nr){
  let f = Object.entries(all).find(([k,v])=>v===nr);
  if(f) return f[0] || nr;
}

export function eventNr(txt){
  let f = Object.entries(all).find(([k,v])=>k===txt);
  if(f) return f[1];
}


export const contact = add({
  status: {
    NEW_CONTACT: 1000,
    CONTACT_CHANGED: 1010,
    CONTACT_DELETED: 1020,
  },
  partner: {
    CONTACT_JOINED_PARTNER: 1030,
    CONTACT_LEFT_PARTNER: 1035
  },
});

export const partner = add({
  status: {
    NEW_PARTNER: 2000,
    LEAD_ACTIVATED: 2005,
    PARTNER_APPROVED: 2010,
    PARTNER_CHANGED: 2020,
    PARTNER_DELETED: 2025,
  },
  op: {
    OP_UPDATED: 2030,
    PARTNER_DISABLED: 2035,
    PARTNER_ENABLED: 2040
  }
});

export const order = add({
  status: {
    NEW_DRAFT: 3000,
    DRAFT_DELETED: 3005,
    NEW_QUOTE: 3010,
    ROUTE_CHANGED: 3015,
    PRICE_CHANGED: 3020,
    SCHEDULE_CHANGED: 3025,
    RESOURCE_ASSIGNED: 3026,
    RESOURCE_REASSIGNED: 3027,
    RESOURCE_CLEARED: 3028,
    RESOURCE_ADDED: 3029,
    RESOURCE_REMOVED: 3030,
    PARTNER_REASSIGNED: 3033,
    DETAILS_CHANGED: 3035,
    QUOTE_EXPIRED: 3040,
    QUOTE_REJECTED: 3045,
    QUOTE_ACCEPTED: 3050,
    QUOTE_REVOKED: 3051,
    QUOTE_DELETED: 3052,
    NEW_ORDER: 3055,
    ORDER_CANCELED: 3060,
    EXECUTION_STARTED: 3065,
    EXECUTION_FINISHED: 3070,
    ORDER_TENYCHANGED: 3071,
    ORDER_DELETED: 3080,
    ORDER_FINISHED: 3081,
    ORDER_ARCHIVED: 3082,
    ARCHIVE_UPDATED: 3083

  },
  driver:{
    DRIVER_INFORMED: 3090,
    TRUCK_ARRIVED: 3091,
    TRUCK_LOADED:  3092,
    TRUCK_UNLOADED: 3093,
    COOLING_TURNED_ON: 3094,
    COOLING_TURNED_OFF: 3095,
  },
  email: {
    QUOTE_SENT: 3100,
    ORDERCONFIRMATION_SENT: 3105,
    ORDERSTATUS_SENT: 3110,
  },
  billing: {
    READY_FOR_BILLING: 3200,
    BILL_CREATED: 3201,
    BILL_ACCEPTED: 3202,
    BILL_REJECTED: 3203,
    NOT_BILLED: 3205
  },
  alert: {
    NEW_ALERT:  3250,
    ALERT_OK:   3260,
  },
  subcontractor:{
    SUBCONTRACTOR_ALLOWED: 3270,
    SUBCONTRACTOR_STATUS_CHANGED: 3272,
    SUBCONTRACTOR_INFORMED: 3274,
    SUBCONTRACTOR_ACCEPTED: 3275,
    SUBCONTRACTOR_REJECTED: 3276,
    SUBCONTRACTOR_REVOKED: 3277,
  },

  poi:{
    POI_CREATED: 3280,
    NO_POI:      3281,
  }, 

});


export const tdriver = add({
  message: {
    TDRIVER_MESSAGE_SENT: 3290,
    TDRIVER_MESSAGE_RECEIVED: 3291,
  }
});

export const exchangeRate = add({
  status: {NEW_EXCHANGERATE: 3300},
})

export const email = add({
  incoming: {
    MAILBOX_SAVED: 3400, MAILBOX_ACTIVATED:3401, MAILBOX_SUSPENDED:3402, MAILBOX_DELETED: 3403, 
    EMAIL_RECEIVED: 3410, 
    EMAIL_READ: 3420,
    EMAIL_OWNER_CHANGED: 3421,    
    EMAIL_MARKED: 3422,
    EMAIL_DELETED:3423,
    EMAIL_REPLIED: 3431,
    EMAIL_FORWARDED: 3432,    
  },
  outgoing: {
    EMAIL_DRAFT_SAVED:3450, EMAIL_DRAFT_DELETED: 3451,
    EMAIL_SENT: 3460, 
    EMAIL_OPENED: 3461, 
    EMAIL_CLICKED: 3462
  },
})

export const fuvarborze = add({
  status: {
    NEW_FUVARBORZE: 3500,
    FUVARBORZE_CHANGED: 3501,
    FUVARBORZE_DELETED: 3502,
  },
})

export const uploads = add({
  op: {
    FILE_UPLOADED: 3600,
    FILE_DELETED:  3601,
  },
})

export default all;



