import React from 'react';
import { Box, Stack, CardHeader, Avatar, Typography, AppBar, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { contrast } from '../../utils.js';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Cancelled from './Cancelled';

const styles = theme => ({
  root: {
    display: 'flex',
    zIndex:100
  },

  fejlec: {
    minWidth: 340,
  },

  content: {
    flexGrow: 10,
    overflow: 'auto',
    padding: 16,
  },
  appBar:{
    position:'relative',
  },
  appBarTop: {
    display: 'flex',
    alignItems: 'center',
  },
  appBarStatus: {
    flexGrow: 100,
    textAlign: 'center',
  },
  appBarMenu: {
  },
  avatar: {
    background: 'inherit',
    color: 'inherit'
  },

});

export const Header = withStyles(styles)(props => {

  let { classes, cancelled, action, bgcolor, color, title, subtitle, icon, sidebar, menu, status, tabs, children, caption, ...other } = props;

  if (!color) color = contrast(bgcolor);

  const theme = createTheme({
    palette: {
      mode: color === '#ffffff' ? 'dark' : 'light',
      primary: {
        main: color
      }
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            background: bgcolor,
            color: color,
          }
        }
      }
    }
  })

  const avatar = icon ? <Avatar className={classes.avatar} sx={{ border: `1px solid ${color}` }} >{icon}</Avatar> : null;

  if (typeof title === 'string') {
    title = <Typography variant="h6" sx={other}>{title}</Typography>;
  }
  if (typeof subtitle === 'string') {
    subtitle = <Typography variant="subtitle1" sx={other}>{subtitle}</Typography>;
  }

  let close = null;
  if (sidebar) {
    close = <IconButton onClick={sidebar.close}><ArrowForwardIcon /></IconButton>
  }



  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.root} sx={{ bgcolor }}>
        <AppBar position="static" className={classes.appBar}>
          <div className={classes.appBarTop}>
            {close}
            <div className={classes.appBarStatus}>
              {caption && <Typography variant="body2">{caption}</Typography>}
              {status}
            </div>
            <Stack direction="row">
              {menu}
            </Stack>
          </div>
          <div  className={classes.appBar}>
          {children || <CardHeader
            color={color}
            avatar={avatar}
            disableTypography
            title={title}
            subheader={subtitle}
            action={action}
          />}
          {cancelled && <Cancelled />}
          {tabs}
          </div>
        </AppBar>
      </Box>
    </ThemeProvider>
  )
})