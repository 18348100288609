export const C = (len, label, opt) => ({label, length: len, type: 'varchar', defval: '', nullval: '', ...opt })
export const UI = (len = 10, label, opt) => ({label, length: len, type: 'int', unsigned: true, defval: 0, nullval: 0, ...opt })
export const I = (len = 10, label, opt) => ({label, length: len, type: 'int', defval: 0, nullval: 0, ...opt })
export const F = (label, opt) => ({label, length: 10, prec: 2, type: 'decimal', defval: 0, nullval: 0, ...opt })
export const GPS = (label, opt) => ({label, length: 9, prec: 6, type: 'decimal', defval: 0, nullval: 0, ...opt })
export const D = (label, opt) => ({label, type: 'date', defval: '', nullval: '', ...opt })
export const DT = (label, opt) => ({label, type: 'datetime', defval: '', nullval: '', ...opt })
export const B = (label, opt) => ({label, type: 'bool',sqltype:'char',length:1, defval: false, nullval: false, ...opt })
export const N = (label, select, opt) => ({label, select, type: 'tinyint', length:4, nullval: 0, ...opt })
export const TEXT = (label, opt) => ({label, type: 'text', defval: '', nullval: '', ...opt })
export const PARAM = (label, opt) => ({label, type: 'json', sqltype: 'mediumtext', defval: '', nullval: '', ...opt })
export const STAMP = (label, opt) => ({label, type: 'timestamp', defval: '', nullval: '', ...opt })
export const M = (label, opt) => ({label, length: 12, prec: 2, type: 'decimal', defval: 0, nullval: 0, ...opt })
export const EMAIL = (label, opt) => ({label, length: 60, type: 'varchar', defval: '', nullval: '',link:'email', ...opt })
export const TEL = (label, opt) => ({label, length: 20, type: 'varchar', defval: '', nullval: '',link:'tel', ...opt })
export const ADDR = (label, opt) => ({label, length: 250, type: 'varchar', defval: '', nullval: '',link:'addr', ...opt })
export const ARRAY = (label, opt) => ({label, type: 'array', notgen:true, defval: [], nullval: [],...opt })

export const hidden = true;
export const notnull = true;
export const notgen = true;
export const pkey = true;
export const autoinc = true;
export const readonly = true;
export const unique = true;
export const optlabel = true; //ezt a mezőt használjuk megjelenítésre az ID helyett

export const STAMPS = {
  created: DT('Létrehozva', ),
  cuser: C(30, 'Létrehozta', ),
  modified: DT('Módosítva', ),
  muser: C(30, 'Modosította', ),
  deleted: DT('Törölve', { hidden }),
  duser: C(30, 'Törölte', { hidden })
};

