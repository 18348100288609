import React, { useCallback } from 'react';
import PageIcon from '@mui/icons-material/OpenInNew';
import { useSidebar } from './Sidebar';
import { useIsMobile } from './Responsive';
import { Link as RouterLink } from 'react-router-dom';
import MUILink from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { normalizeTel, dateToMoment } from '../../utils.js';
import MapIcon from '@mui/icons-material/Map';

let links = {};

export function registerLink({ id, page, format }) {
  links[id] = { page, format };
};

export const getPageLink = (link) => {
  if (!link) return false;
  try {
    const id = Object.keys(link)[0];
    if (!id || !link[id]) {
      return false;
    }

    const value = link[id].id || link[id].sorszam || link[id].kod || link[id];
    const reg = links[id];
    if (reg && !reg.page) {
      return false;
    }
    const url = (reg && reg.page) || id;
    return `/${url}/${value}`;
  } catch (e) {
    console.log(e);
    return false;
  }
}

const pgStyle = {
  fontSize: '15px',
  cursor: 'pointer',
  top: 3,
  position: 'relative',
  marginLeft: 4,
  marginRight: 4
}

const sbStyle = {
  cursor: 'pointer',
}


export const PageLink = ({ style, color, ...link }) => {
  const page = getPageLink(link);
  if (useIsMobile()) return null;
  return page ? <MUILink underline="none" className="pagelink" style={{ color }} component={RouterLink} to={page}><PageIcon style={style} /></MUILink> : null
}

export const PageLinkButton = ({ style, color, ...link }) => {
  const page = getPageLink(link);
  if (useIsMobile()) return null;
  return page ? <IconButton style={{ color }} to={page} component={RouterLink}><PageIcon style={style} /></IconButton> : null
}


const defFormat = (value) => value ? value.rnev || value.nev || value.id || value : '';

export const SidebarLink = ({ children, color, style, className, ...link }) => {
  const { setSidebar } = useSidebar();
  const open = useCallback(e => {
    e.stopPropagation();
    setSidebar(link);
  }, [link, setSidebar]);

  if (!link) return null;
  const id = Object.keys(link)[0];
  if (!id) return null;
  const value = link[id];
  const reg = links[id];
  if (!children) {
    if (reg && reg.format) {
      children = reg.format(value);
    } else {
      children = defFormat(value);
    }
  }

  return <span className="applink">
    <MUILink component="span"
      onClick={open}
      style={sbStyle}
      color={color}>{children}
    </MUILink>
    <PageLink color={color} style={pgStyle} {...link} />
  </span>
}

const szlalink = ({ kod, kelte }) => {
  return `/pdf/${dateToMoment(kelte).format('YYYY/MM/DD')}/${kod.replaceAll('/', '-')}.pdf`;
}

export const Link = ({ children, color, mailto, tel, addr, href, addrbutton, szamla, ...link }) => {
  if (href) return <MUILink href={href} target="_blank" color={color}>{children || href}</MUILink>
  if (mailto && mailto.address) return <MUILink href={`mailto:${mailto.address}`} color={color}>{children || mailto.name ? `${mailto.name} <${mailto.address}>` : mailto.address}</MUILink>
  if (mailto) return <MUILink href={`mailto:${mailto}`} color={color}>{children || mailto}</MUILink>
  if (tel) return <MUILink href={`tel:${normalizeTel(tel)}`} color={color}>{children || tel}</MUILink>
  if (addr) return <MUILink href={'https://google.com/maps?q=' + addr} target='_google_maps'>{children || addr}</MUILink>
  if (addrbutton) return <IconButton href={'https://google.com/maps?q=' + addr} target='_google_maps' style={{ paddingTop: 0, paddingBottom: 0 }} ><MapIcon /></IconButton>
  if (szamla) {
    console.log("Számla link",szamla);
    return <MUILink href={szlalink(szamla)} target="szamla">{szamla.kod}</MUILink>
  }

  return <SidebarLink color={color} {...link}>{children}</SidebarLink>
}

export const AppLink = ({ children, ...other }) => {
  return <MUILink component={RouterLink} {...other}>{children}</MUILink>
}

export const AppLinkButton = ({ children, ...other }) => {
  return <Button component={RouterLink} {...other}>{children}</Button>
}

export const AppLinkIcon = ({ children, ...other }) => {
  return <IconButton component={RouterLink} {...other}>{children}</IconButton>
}