let all = {};
function add(e) {
  Object.values(e).map(v => {
    Object.entries(v).map(([k, v]) => {
      if (all[k]) throw new Error('Duplicate command key ' + k);
      if (Object.values(all).includes(v)) throw new Error(`Duplicate command value ${k} = ${v}`);
      all[k] = v;
    })
  })
  return e;
}


export function commandTxt(nr) {
  let f = Object.entries(all).find(([k, v]) => v === nr);
 // console.log("commands:",f,all);
  if (f) return f[0] || nr;
}

export function commandNr(txt) {
  let f = Object.entries(all).find(([k, v]) => k === txt);
  if (f) return f[1];
}

export const appuser = add({
  status: {
    CREATE_USER: 1, UPDATE_USER: 2, DELETE_USER: 3, CHANGE_PASSWORD: 5
  },

  email: {
    SEND_ALERT: 6, SEND_REMINDER: 7, SEND_REPORT: 8,
  },
})


export const contact = add({
  status: { SAVE_CONTACT: 10, DELETE_CONTACT: 11 },
  partner: { JOIN_CONTACT_PARTNER: 20, LEAVE_CONTACT_PARTNER: 21 },
})

export const partner = add({
  status: { SAVE_PARTNER: 30, APPROVE_PARTNER: 32, DELETE_PARTNER: 34 },
  addr: { SAVE_PARTNER_ADDRESS: 38, DELETE_PARTNER_ADDRESS: 39 },
  op: { DISABLE_PARTNER: 40, ENABLE_PARTNER: 41 }
})

export const exchangeRate = add({
  status: {SET_EXCHANGERATE: 50, REFRESH_EXCHANGERATE:51},
})




export const driver = add({
  status: {
    SAVE_DRIVER: 100, JOIN_DRIVER: 110, LEAVE_DRIVER: 111,
  }
})


export const order = add({
  status: {
    SAVE_DRAFT: 150, DELETE_ORDER: 151, CREATE_QUOTE: 152, UPDATE_QUOTE: 153, REVOKE_QUOTE: 154, REJECT_QUOTE: 155, ACCEPT_QUOTE: 156,
    CREATE_ORDER: 157, UPDATE_ORDER: 158, CANCEL_ORDER: 159, FINISH_ORDER: 160,
    UPDATE_TENYADAT: 161,
  },
  email: {
    SEND_QUOTE: 170, SEND_QUOTEREMINDER: 171, SEND_ORDERCONFIRMATION: 172, SEND_ORDERSTATUS: 173, SEND_ORDERFULFILLED: 174,
  },

  billing: {
    SUBMIT_FOR_BILLING: 180,
    CREATE_BILL: 181
  },
  poi: {
    CREATE_POI: 184,
    NO_POI: 185,
  }
})

export const email = add({
  incoming: {
    SAVE_MAILBOX: 200, ACTIVATE_MAILBOX: 201, SUSPEND_MAILBOX: 202, DELETE_MAILBOX:203, 
    SET_EMAIL_OWNER: 205, MARK_EMAIL: 206, DELETE_EMAIL:207,
  },
  outgoing: {
    SAVE_EMAIL_DRAFT: 220, DELETE_EMAIL_DRAFT: 221,
    SEND_EMAIL: 222, FORWARD_EMAIL: 224
  },
})

export const fuvarborze = add({
  status:{
    ASSIGN_SUBCONTRACTOR: 230,
    SET_SUBCONTRACTOR_STATUS: 231,
  }
})

export const planner = add({
  timing: {
    CHANGE_SCHEDULE:240,
  },
  resource: {
    SET_RESOURCE:250, SET_TRAILER:251, DELETE_RESOURCE:252,
    ADD_CHANGEPOINT:253, REMOVE_CHANGEPOINT:254, 
    SET_EXECUTION_DETAILS: 255,
  },
  taskforsale: {
    ADD_TASKFORSALE: 260,
    REMOVE_TASKFORSALE: 261,    
    SET_TASKFORSALE_STATUS:263,    
  },
  truckavailable: {
    ADD_AVAILABLE_TRUCK: 265,
    REMOVE_AVAILABLE_TRUCK: 266,
    SET_AVAILABLE_TRUCK_STATUS:267,    
  }
});


//console.log("COMMANDS", all);
export default all;



