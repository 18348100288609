import React, { createContext, useCallback, useContext, useState, useMemo } from 'react';
import moment from 'moment';
import hu from './hu.json';
import de from './de.json';
import en from './en.json';
import _ from 'lodash';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {stampToMoment,dateToMoment} from '../../utils';

const txt = { hu, de, en };

export class I18n {
  constructor(locale) {
    this.locale = locale || 'hu';
  }

  setLocale = l => this.locale = l;

  stampformat = {
    hu: 'YYYY.MM.DD HH:mm',
    de: 'DD.MM.YYYY, HH:mm',
    en: 'MMMM DD YYYY HHmm'
  }

  __stamp = (date) => {
    if (!date) return '';
    let m = stampToMoment(date);
    m.locale(this.locale);
    return m.format(this.stampformat[this.locale]);
  }

  dateformat = {
    hu: 'YYYY.MM.DD',
    de: 'DD.MM.YYYY',
    en: 'YYYY.MM.DD'
  }

  __date = (date) => {
    if (!date) return '';
    let m = dateToMoment(date);
    m.locale(this.locale);
    return m.format(this.dateformat[this.locale]);
  }

  today = () => {
    let m = moment();
    m.locale(this.locale);
    return m.format(this.dateformat[this.locale]);
  }

  __enum = (n, val) => {
    if (!val) return '';
    return this.__(`enum.${n}.${val}`);
  }

  __ = (str) => {
    return _.get(txt[this.locale], str) || str;
  }

  getLocale = () => {
    return { hu: 'hu-HU', de: 'de-DE', en: 'en-EN' }[this.locale];
  }
}

const LocaleCtx = createContext();
export const useLocale = () => useContext(LocaleCtx);

export const LocaleContext = ({ locale, children }) => {
  const [i18n, setI18n] = useState(new I18n(locale));
  const setLocale = l => {
    if (l !== i18n.locale) setI18n(new I18n(l));
  }

  console.log("LocaleProvider", locale);

  return <LocalizationProvider dateAdapter={DateAdapter} locale={i18n.getLocale()}>
    <LocaleCtx.Provider value={{ ...i18n, setLocale }}>{children}</LocaleCtx.Provider>
  </LocalizationProvider>
}
