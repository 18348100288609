import React, { useState } from 'react';
import { Grid, Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import cn from 'classnames';
import { dateToMoment, stampToMoment, oraperc } from '../../utils.js';
import { TetelJelleg } from '../ui/alapadat';
import { useSidebar } from '../components/Sidebar';
import CheckIcon from '@mui/icons-material/Check';
import { useLocale } from '../locales';

export function Tetel({ rakodo, start, end }) {
  const { __date } = useLocale();

  let cim;
  cim = <div>
    <Typography variant="caption" component="div" sx={{ color: "text.secondary" }}>
      {rakodo.orszag} {rakodo.irsz} {rakodo.varos}, {rakodo.cim}
    </Typography>
  </div>
  let nyitvatart = null;
  let arrival = [];
  let cargo = [];
  let info = null;

  if (rakodo.time) arrival.push(rakodo.time);
  if (rakodo.arrival) arrival.push(rakodo.arrival);
  if (rakodo.arrivalend && rakodo.arrivalend!==rakodo.arrival) arrival.push(' - ' + rakodo.arrivalend);
  arrival = arrival.length ? <Typography key="arrival" variant="caption" component="div" sx={{ color: "text.secondary" }}>{arrival.join(' ')}</Typography> : null;

  if (rakodo.mennyiseg) {
    cargo.push(`${rakodo.mennyiseg} ${rakodo.me}`);
  }
  if (rakodo.suly) cargo.push(`${rakodo.suly} kg`);
  if (rakodo.cargo) cargo.push(rakodo.cargo);
  if (rakodo.rakszam) cargo.push(rakodo.rakszam);
  if (rakodo.cargomemo) cargo.push(rakodo.cargomemo);
  cargo = cargo.length ? <Typography key="cargo" variant="caption" component="div" sx={{ color: "text.secondary" }}>{cargo.join(' | ')}</Typography> : null;
  return <Typography component="div" variant="body2" className={cn('tetel', 'active', { hasprev: !start, hasnext: !end })}>
    <div className='left'></div>
    <div className={cn('right', ['felrako', 'akasztas', 'akasztas', 'lerako', 'tankolas', 'via'][rakodo.jelleg - 1])}>
      <div className="primary" style={{ width: 300 }}><TetelJelleg tetel={rakodo} fontSize="12" />{__date(rakodo.date)} - {rakodo.epulet || rakodo.varos}</div>
      {cim}
      {nyitvatart}
      {arrival}
      {cargo}
      {info}
    </div>
  </Typography>
}


export function UtvonalData({ rakodo }) {
  //  console.log("Útvonal", { tetel, rakodo });
  return <div className="timeline" style={{ paddingLeft: 4 }}>
    {rakodo.map((r, i) => {
      return <Tetel
        key={i}
        rakodo={r}
        start={!i}
        end={i === rakodo.length - 1}
      />
    })}
  </div>
}

