import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { withStyles } from '@mui/styles';
import DataDisplay from '../components/DataDisplay'
import { Link } from '../components/Link'
import { Paper, Button, Grid, Chip, ButtonGroup, LinearProgress } from '@mui/material';
import { format, dateToMoment } from '../../utils.js';
import { UtvonalData } from './UtvonalData.js';
import { postCommand, useRefresh, useJSON } from '../data/fetch';
import { COMMAND } from '../../common';
import { Check, Delete, Cancel, SettingsApplicationsOutlined } from '@mui/icons-material';
import { useDialog } from '../components/Dialog';
import { useConfirm } from 'material-ui-confirm';

const styles = theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    padding: 16,
    background: theme.palette.background.paper,
    zIndex: 1,
    maxWidth: 450,
  },
});

let OrderAlapadatok = props => {
  const canEdit = true;
  const { classes, action, a } = props;
  let { rakodo, tetel, kocsi, potkocsi, eszkoz, rjelleg } = a;
  const { setDialog } = useDialog();
  const uploads = useJSON({ url: `/uploads/order/${a.id}` });
  const [csatol, setCsatol] = useState(false);
  const confirm = useConfirm();

  useEffect(() => setCsatol(false), [a.id]);
  useEffect(() => setCsatol(uploads), [uploads]);
  const refresh = useRefresh();
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(async acceptedFiles => {
    setUploading(true);
    console.log('dropped', acceptedFiles);
    for (let i = 0; i < acceptedFiles.length; i++) {
      let formData = new FormData();
      formData.append("upload", acceptedFiles[i]);
      const response = await fetch('/json/uploads/order/' + a.id, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          //          'X-Access-Token': getToken(),
        },
        body: formData
      });
      console.log("uploaded", response);
    }
    refresh('/uploads/order/' + a.id);
    setUploading(false);
    //setDialog({ id: 'tender', op: 'update', rec: value });
  }, [a.id, setUploading, refresh, setDialog])
  //  console.log("Orderalapadatok", a);

  const deleteFile = useCallback((ev, file) => {
    ev.preventDefault();
    ev.stopPropagation();
    confirm({
      title: 'Csatolmány törlése',
      description: 'A törlést nem lehet visszavonni!',
      confirmationText: 'Végleges törlés'
    })
      .then(() => {
        fetch(`/json/uploads/order/${a.id}/${file}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
          },
        })
      })
      .catch(() => { /* meggondolta magát */ });
  }, [a.id, confirm]);


  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  //  console.log("Order Alapadatok",a.tetel);  
  kocsi = kocsi && kocsi.length && kocsi;
  potkocsi = potkocsi && potkocsi.length && potkocsi;

  return (
    <div className={classes.root} style={isDragActive ? { background: "#efe" } : {}}>
      <div {...getRootProps({
        onClick: event => {
          //          event.preventDefault();
          event.stopPropagation();
        }
      })}>
        <input {...getInputProps()} />
        <Grid container>
          <DataDisplay xs={4} label="Szükséges eszköz" data={eszkoz} />
          {a.lemondva && <>
            <DataDisplay xs={4} label="Lemondva" data={a.lemondva.stamp} format={format.fromNow} />
          </>}
        </Grid><Grid container>
          <DataDisplay xs={3} label="Vontató" data={kocsi} component="div" />
          <DataDisplay xs={3} label="Pótkocsi" data={potkocsi} component="div" />
          <DataDisplay xs={12} label="Feladat">
            <UtvonalData tetel={tetel} rakodo={rakodo} expanded />
          </DataDisplay>
          <DataDisplay xs={12} label="Csatolmányok">
            {csatol && csatol.length && csatol.map(f => <Chip key={f}
              component="a"
              href={`/uploads/order/${a.id}/${f}`}
              target="_blank"
              variant="outlined"
              clickable
              onDelete={ev => deleteFile(ev, f)}
              label={f} />)}
          </DataDisplay>
          {uploading && <LinearProgress />}
        </Grid>
      </div>
    </div>
  )
}

OrderAlapadatok = withStyles(styles)(OrderAlapadatok);

export default OrderAlapadatok;