import React, { useCallback } from 'react'
import MUITextField from '@mui/material/TextField';
import _ from 'lodash'
import { createField } from './createField';


export function TextInput(props) {
  let { field, form, touched, meta, classes, calculate, parse, format, inputStyle, inputProps, ...other } = props;
  if (classes) other.InputProps = { ...other.InputProps, classes };
  if (!field.value) field.value = '';

  const onBlur = (ev) => {
    //    ev.stopPropagation();
    field.onBlur(ev); //tovább adjuk
    let newValue = field.value;
    if (parse) newValue = parse(ev.target.value);
  //  console.log("onBlur",field.name, newValue);
    _.set(form.values, field.name, newValue);

/*    if (_.get(form.touched,field.name)) {
      let newValue = field.value;
      if (parse) newValue = parse(ev.target.value);
      console.log("onBlur",field.name, newValue);
      _.set(form.values, field.name, newValue);
      field.value = newValue;
      if (calculate) calculate({ oldValue: field.value, newValue, rec: form.values, field, form });
    }*/
  }

  const handleChange = useCallback((ev) => {
    let { value, files } = ev.target;
    if (files) {
  //    console.log("File value", files);
      form.setFieldValue(field.name + '_files', files);
    }
    if (parse) {
      value = parse(value);
      //      ev.target.value = value; //nem jó, mert nem lehet pl. tizedes vesszőt beírni...
    }

    if (calculate) {
      const oldValue = _.cloneDeep(field.value);
      let rec = form.values;
      _.set(rec, field.name, value);
      field.value = value;
      calculate({ oldValue, newValue: value, field, form, rec })
    }
    field.onChange(ev);        
  }, [field, calculate, form]);

  const error = props.error || _.get(form.errors,field.name);
  let { value } = field;
  if (inputStyle) {
    let style = inputStyle(value, form.values);
    inputProps = { ...inputProps, ...style };
  }
  if (value && format) value = format(value);

  inputProps = { ...inputProps, autoComplete: "off", autocomplete: 'new-password' };

  return (
    <MUITextField
      variant="standard"
      fullWidth
      {...field}
      {...other}
      inputProps={inputProps}
      value={value}
      onChange={handleChange}
      margin="normal"
      error={!!error}
      onBlur={onBlur}
      helperText={error}
      InputLabelProps={other.InputLabelProps || {}}
    />
  );
}


export const TextField = createField(TextInput);
