
export default function Cancelled() {
  return <div style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }}>
    <div style={{
      position: 'absolute', top: '50%', left: '50%',
      transform: 'translateX(-50%) translateY(-50%) rotate(-20deg)',
      fontSize: 24,
      color: 'red', display: 'flex',padding:10,border:'2px solid red',borderRadius:10
    }}>
      LEMONDVA
    </div>
  </div>
}