import { withStyles } from '@mui/styles';
import { Typography, IconButton, Grid, Chip } from '@mui/material';
import { Link } from './Link'


const styles = () => ({
  root: {
    marginBottom: 8,
    minWidth: 100,
    display: 'flex',
    justifyContent: 'space-between'
  },
  label: {
  },
  value: {
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    // maxWidth: 240,
  },
  button: {
    display: 'inline-block',
    float: 'right'
  }
});

let DataDisplay = withStyles(styles)(props => {
  let { classes, icon, label, action, children, data, link, format, emptyWarning, xs, ...other } = props;

  let LabelIcon = icon;
  if(icon) icon = <LabelIcon  fontSize="small" style={{color:'#666', top:6, position:'relative'}}/>
  let button = null;
  if (action) {
    const { Icon, onClick } = action;
    button = action.button;
    if (!button && Icon) {
      button = <IconButton className={classes.button} onClick={onClick}><Icon fontSize="small" /></IconButton>
    }
  }

  if (!children) {
    if (data) {
      if (format) {
        children = format(data);
      } else {
        if(Array.isArray(data)){
          if(props.component){
            let Component = props.component;
            children = data.map((d,i)=><Component key={d}>{d}</Component>)
          }else{
            children = data.map((d,i)=><Chip variant="outlined" size="small" label={d} key={d} />)
          }
        }else{
          children = data;
        }
      }
    }
    if (link) {
      let empty = Object.values(link).reduce((acc, v) => (acc && !v), true);
      if (empty) {
        children = null;
      } else {
        children = <Link {...link} />
      }
    }
    if (!children && emptyWarning) children = <span style={{ color: '#fbb' }}>Ez egy fontos adat, ami hiányzik</span>;
  }

  if (!children) return null;

  return <Grid xs={xs || 12} item className={classes.root} {...other}>
    <div>
      <Typography variant='caption' className={classes.label}>{label} {icon}</Typography>
      <Typography component="div" variant='body2' className={classes.value}>{children}</Typography>
    </div>
    {button}
  </Grid>
});

export default DataDisplay;