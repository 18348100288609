import React from 'react'
import MUISwitch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import IconButton from '@mui/material/IconButton';
import { createField } from './createField';

export const Switch = ({ field, form, calculate, ...other }) => {

  const handleChange = (ev, value) => {
    form.setFieldValue(field.name, value);
  }

  console.log("Switch", { field, other });
  return (
    <FormControl fullWidth margin={other.margin}>
      <FormControlLabel
        style={{ width: '95%', color: field.value ? 'inherit' : '#ccc' }}
        label={other.label}
        control={
          <MUISwitch
            name={field.name}
            checked={field.value ? true : false}
            onChange={handleChange}
            color="primary"
            value={field.name}
            {...other}
          />}
      />
    </FormControl>
  )
}

export const SwitchField = createField(Switch);

const Star = (
  { field, form, calculate, ...other }
) => {

  const handleChange = (ev) => {
    form.setFieldValue(field.name, !field.value);
    if (calculate) {
      const oldValue = field.value;
      field.value = !field.value;
      calculate({ oldValue, newValue: field.value, field, form })
    }
  }

  return (
    <IconButton onClick={handleChange}>
      {field.value ? <StarIcon /> : <StarBorderIcon />}
    </IconButton>
  )
}

export const StarField = createField(Star);
