import React, { useState, useEffect } from 'react';
import { ResponsiveLayout } from '../components/Responsive';
import OrderAlapadatok from './OrderAlapadatok';
import { useJSON } from '../data/fetch';
import { useSidebar } from '../components/Sidebar'
import { useDialog } from '../components/Dialog'
import { Header } from '../components/Header';
import { Link } from '../components/Link';
import { Formik, useField } from 'formik';
import { Panel, useTab, PageTabs, PanelField } from '../components/Panel';
import Loading from '../components/Loading';
import { color } from '../ui/alapadat';
import { withStyles } from '@mui/styles';
import { Box, Stack, Grid, Container, Avatar, Button, Paper, Typography } from '@mui/material';
import { stampToMoment, stampFromMoment, format } from '../../utils.js';
import SendIcon from '@mui/icons-material/Send';
import { isMatch } from 'lodash';
import { FlightLand, FlightTakeoff, Pause, PlayArrow, Save } from '@mui/icons-material';
import { TextField } from '../components/input';
import { UtvonalData } from './UtvonalData';
import { useLocale } from '../locales';
import EventList from './EventList';
import { EVENT } from '../../common';
import Map from '../components/Map';
import { Marker,CircleMarker } from 'react-leaflet';

const OrderHeader = ({ a, sidebar, tabs }) => {
  if (!a) return null;
  return <Typography
    variant="h6"
    sx={{ color: 'primary.main', textAlign: 'center', width: '100%' }}>
    Megbízás {a.ref}
  </Typography>
}

const styles = theme => ({
  root: {
    top: 0, bottom: 0, left: 0, right: 0
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    //    boxShadow: theme.shadows[4],
    zIndex: 2,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    background: '#eee',
    flexGrow: 2,
    padding: 16,
    overflowY: 'auto'

  },
  right: {
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[4],
    minWidth: 460,
    maxWidth: 460,
    overflowY: 'hidden',
    zIndex: 2,
  }

});

function Data({ label, value }) {
  return <Box sx={{ mb: 2 }}>
    <Typography variant="caption">{label}</Typography>
    <Typography variant="body2"><b>{value}</b></Typography>
  </Box>
}

let OrderWindow = ({ a, partner, classes }) => {
  const { __, __enum } = useLocale();

  if (!a) return <Loading />
  console.log("ORder", a);
  let {kocsi,potkocsi} = a.tetel.reduce((acc,t)=>{
    if(t.kocsi && !acc.kocsi.includes(t.kocsi)) acc.kocsi.push(t.kocsi);
    if(t.potkocsi && !acc.potkocsi.includes(t.potkocsi)) acc.potkocsi.push(t.potkocsi);
    return acc;
  },{kocsi:[],potkocsi:[]});
  return <Grid container spacing={1}>
    <Grid item xs={12} md={4}>
      <Data label={__('customer.partner')} value={partner.name} />
      <Data label={__('customer.reference')} value={a.ref || __('customer.missingref')} />
      <Data label={__('customer.vehicle')} value={__enum('eszkoz', a.eszkoz)} />
      <Data label={__('customer.truck')} value={kocsi.join(', ')} />
      <Data label={__('customer.trailer')} value={potkocsi.join(', ')} />
      <Typography variant="caption">{__('customer.route')}</Typography>
      <UtvonalData rakodo={a.rakodo} />
    </Grid>
    <Grid item xs={12} md={4}>
      <EventList list={a.events.map(ev => {
        ev.r = a.rakodo[ev.rakodo - 1];
        return ev;
      })} />
    </Grid>
    <Grid item xs={12} md={4} style={{minHeight:300}}>
      {a.gps ? 
       <Map center={a.gps}>
          <CircleMarker center={a.gps} radius={15} pathOptions={{ color: 'red' }}/>
          {a.rakodo.map(r=><Marker key={r.idx} position={[r.plat,r.plon]} />)}          
        </Map> :
       <Typography variant="body2" color="disabled">{__('customer.notracking')}</Typography>
      }
    </Grid>
  </Grid>
}

OrderWindow = withStyles(styles)(OrderWindow);

export default ({ sidebar, match }) => {
  const { locale, setLocale } = useLocale();
  const data = useJSON({ url: '/json' + window.location.pathname });
  console.log("OrderPanel", data, match, locale, setLocale);
  useEffect(() => {
    if (data && (data.partner.language || 'hu') !== locale) {
      console.log('át kell váltani a nyelvet', data.partner.language, locale);
      setLocale(data.partner.language);
    }
  }, [data]);

  if (!data) {
    return <Loading />
  } else {
    return <OrderWindow a={data.order} partner={data.partner} />
  }
}
