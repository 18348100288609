import React, { useState, useCallback } from 'react';
import { Formik } from 'formik';
import { IconButton, Button, LinearProgress, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Prompt, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { MetaContext,defval } from '../data/meta';
import { fetchJSON, useRefresh } from '../data/fetch';
import { useSidebar } from './Sidebar';

export const Actions = React.memo(({ left, cancel, okLabel, disabled }) => (
  <DialogActions>
    {left} {left && <div style={{ flex: '1 0 0' }} />}
    <Button onClick={cancel} >Mégsem</Button>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      autoFocus
      disabled={disabled}>
      {okLabel || "Ok"}
    </Button>
  </DialogActions>
))

export const RecordDialog = (props) => {
  let { open: { id, op, rec }, onOk, cancel, actions, title, children, canceled } = props;
  const { enqueueSnackbar } = useSnackbar();
  const submit = useCallback(async (values) => {
    try {
      const {msg, redirect, next} = await onOk(values);
      cancel({next, redirect});
      console.log("OnOK msg:", msg);
      if (msg) enqueueSnackbar(msg, { variant: 'success' });
    } catch (e) {
      console.log("Error", e);
      enqueueSnackbar(String(e), { variant: 'error' });
    }
  }, [cancel, onOk, enqueueSnackbar]);

  return (
    <MetaContext rec={id} op={op}> {/*ez a saját metadatánkhoz való hozzáférést biztosítja*/}
      <Formik initialValues={rec} onSubmit={submit} {...props}>{({ handleSubmit, isSubmitting, isValidating, dirty }) => (
        <form onSubmit={handleSubmit} style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
          <Prompt when={dirty && !canceled} message='Nem mentette az adatokat! Ha kilép elvesznek!' />
          {title && <DialogTitle>{title}</DialogTitle>}
          <DialogContent>
            {children}
          </DialogContent>
          {isSubmitting && <LinearProgress />}
          {actions || <Actions {...props} />}
        </form>
      )}
      </Formik>
    </MetaContext>
  );
}


export function submitData({ op, table, url, method, msg, refresh, sidebar, onSuccess, red }) {
  if (!method) method = { insert: 'POST', update: 'PATCH', del: 'DELETE' }[op];

  return async function (values) {
    if (!url) url = op === 'insert' ? `/${table}` : `/${table}/${values.id}`;
    const {value,error,...other} = await fetchJSON(method, url, values);
    let ret = {msg: msg || other.msg};
    if (error) throw error;
    if (onSuccess) {
      const os = onSuccess({ value, op});
      if(os) ret = Object.assign(ret, os);
    } else {
      if (op === 'insert') {
        ret.redirect = `/${table}/${value.id}`;
      }
    }
    if (op === 'del') {
      console.log("DEL", { sidebar });
      if (sidebar && sidebar.open && sidebar.open[table] && sidebar.open[table].id === values.id) {
        setTimeout(sidebar.close, 10);
      }
    }
    if (refresh) setTimeout(refresh, 1);
    return ret;
  }
}


export const DataDialog = (props) => {
  const refresh = useRefresh();
  const sidebar = useSidebar();

  let { open, msg, title, label, children, okLabel, onSuccess } = props;
  const [op, setOp] = useState(props.op || open.op);

  let { rec, id, table } = open;
  if (!rec) rec = defval(id) //meta default values;  
  if (op === 'insert') rec = defval(id, rec);
  console.log("DATADIALOG", { id, op, rec });
  if (!title && label) {
    switch (op) {
      case 'insert':
        title = 'Új ' + label.toLowerCase();
        if (!msg) msg = 'Új ' + label.toLowerCase() + ' elmentve';
        break;
      case 'udpate':
        title = label + ' szerkesztése';
        if (!msg) msg = label + ' módosítva';
        break;
      case 'del':
        title = label + ' törlése';
        if (!msg) msg = label + ' törölve';
        break;
    }
  }

  const submit = submitData({ table: table || id, op, refresh, sidebar, msg, onSuccess });
  let actions = false;
  if (!okLabel) okLabel = op === 'del' ? "Törlés" : "Mentés";
  if (op === 'update') {
    const del = () => setOp('del');
    actions = <Actions okLabel={okLabel} {...props} left={<IconButton onClick={del}><DeleteIcon /></IconButton>} />
  }

  console.log("DataDialog", { op, title, id });

  return <RecordDialog onOk={submit} okLabel={okLabel} actions={actions} title={title} {...props}>
    {children}
  </RecordDialog>
}