import React from 'react'
import DateTimePicker from '@mui/lab/DatePicker';
import { Field, FastField } from 'formik'
import moment from 'moment'
import { InputAdornment, FormControl, TextField, Typography } from '@mui/material';
import { parseStamp } from '../../../utils.js';
import { stampToMoment, stampToStr } from '../../../utils.js';
import { createField } from './createField';
import _ from 'lodash';

const format = (value) => {
  const ret = value ? moment(value, 'YYYY.MM.DD HH:mm') : null;
  return ret;
}
const parse = (value,minutes) => {
  return value && value.isValid()? moment(value).format('YYYY-MM-DD HH:mm:ss') : null;
};


class StampText extends React.Component {
  prev = '';
  state = { hetnapja: '' }

  render() {
    let { inputProps, openTo, ...other } = this.props;
    //console.log("DateText",{value,other});
    const { value, onChange } = inputProps;
//    console.log("inputProps.value", value);
    inputProps = {
      ...inputProps,
      placeholder: other.placeholder || 'ééhhnn óópp',
      onChange: (ev) => {
//        console.log("onChange", ev.target.value);
        let v = ev.target.value;
        if (v.length < this.prev.length) {
//          console.log("Törlés", v, this.prev);
          this.prev = v;
          onChange(ev);
          return;
        } else {
//          console.log("gépelés", v, this.prev);
        }
        let parsed = parseStamp(v);
        if (parsed && parsed.isValid()) {
          ev.target.value = stampToStr(parsed);
        }
        this.prev = ev.target.value;

        onChange(ev);
      }
    };

    return <TextField fullWidth variant="standard" {...other} inputProps={inputProps} />
  }
};

class Input extends React.Component {
  // onClick = (e) => e.stopPropagation();


  render() {
//    console.log("StampInput", this.props);
    return (
      <FormControl margin={this.props.margin} onClick={this.onClick}>

        <DateTimePicker
          //          minDateMessage='Túl korai a kiválasztott időpont'
          //          maxDateMessage='Túl távoli a kiválasztott időpont'
          //          invalidDateMessage='Érvénytelen dátum'
          //          invalidLabel=''
          //          emptyLabel=''
          allowSameDateSelection
          cancelText='Mégsem'
          clearable
          clearText='Töröl'
          disableMaskedInput
          inputFormat='YYYY.MM.DD HH:mm'
          views={['hours', 'minutes', 'day', 'month', 'year']}
          //mask="YYYY.MM.DD"
          autoOk          
          renderInput={(params) => <StampText {...params} error={this.props.error} helperText={this.props.helperText} placeholder={this.props.placeholder} InputLabelProps={{shrink:true}}/>}
          {...this.props}

        />
      </FormControl>
    );
  }
}


class StampInput extends React.Component {

  handleChange = (value) => {
    let { form, field, calculate, parser } = this.props;
    const val = parser? parser(value) : parse(value);
    form.setFieldValue(field.name, val);
    if (calculate) {
      const oldValue = field.value;
      field.value = val;
      calculate({ oldValue, newValue: field.value, field, form })
    }
  }


  render() {

    const { field, form, calculate, helperText, ...other } = this.props;
    const value = format(field.value);
    const error = _.get(form.errors, field.name);
    if(error) console.log("StampInput",form.errors,field.name,error);

    return (
      <Input
        {...field}
        {...other}
        error={!!error}
        helperText={error || helperText}
        value={value}
        margin="normal"
        onChange={this.handleChange}
      />

    )
  }
};

export const StampField = createField(StampInput);

export default StampField;