import React, { useState } from 'react';
import { Grid, Tabs, Tab, Box, Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { withStyles } from '@mui/styles';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    width: '100%',
    maxWidth: '100vw',
    alignItems: 'stretch',
    height: '100%'
  },
  fejlec: {
  },
  content: {
    flexGrow: 100,
    overflowY: 'auto',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
  },

  appBarTop: {
    display: 'flex',
    alignItems: 'center',
  },
  appBarStatus: {
    flexGrow: 100,
    textAlign: 'center',
  },
  appBar: {
    transition: 'background-color 0.2s ease',
  },

});

const tabState = {};

export const useTab = (id) => {
  const [tab, setTab] = useState(tabState[id] || 0);
  const saveTab = (value) => {
    setTab(value);
    if (!id) return;
    tabState[id] = value;
  };

  return [tab, saveTab];
}

export const PageTabs = ({ pages, tab, setTab }) => {
  return <Tabs value={tab} onChange={(event, value) => { setTab(value); }} variant="scrollable" >
    {pages.map(label => <Tab key={label} component="div" label={label} style={{ minWidth: 70 }} />)}
  </Tabs>
}

export const Panel = withStyles(styles)(({ header, content, classes }) => {
  return (
    <div className={classes.root}>
      {header}
      <div className={classes.content}>
        {content}
      </div>
    </div>
  )
})

export function PanelField({ icon, caption, primary, secondary, children, action, noborder,primaryStyle, ...other }) {
  if (icon) icon = <Box sx={{ pr: 1 }}>{icon}</Box>
  let style;
  if (noborder) {
    style = {
      boxShadow: 'none',
      border: 'none',
      root: {
        '&:before': {
          display: 'none'
        }
      }

    };
  }

  return (
    <Accordion square TransitionProps={{ unmountOnExit: true }} style={style} {...other}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      ><div style={{ width: '100%', display: 'flex', alignItems: 'end' }}>
          {icon}
          <div style={{ flexGrow: 100 }}>
            <Typography component="div" variant="caption">{caption}</Typography>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="body2" sx={{ minWidth: '50%', fontWeight: 'bold',...primaryStyle }}>
                {primary}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {secondary}
              </Typography>
            </Box>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {children}
          {action && <Grid item xs={12} sx={{ textAlign: 'right' }}>
            {action}
          </Grid>}
        </Grid>
      </AccordionDetails>
    </Accordion>)
}

export default Panel;